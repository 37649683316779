import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMenu from "./QuarklycommunityKitMenu";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"padding": "16px 0 16px 0",
	"quarkly-title": "Header",
	"align-items": "center",
	"justify-content": "center",
	"overflow-y": "hidden",
	"overflow-x": "hidden"
};
const overrides = {
	"link": {
		"kind": "Link",
		"props": {
			"transition": "opacity 200ms ease",
			"quarkly-title": "Link",
			"href": "/",
			"position": "relative"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/655c9b91867c86001f18116f/images/logoHD.png?v=2023-11-21T21:44:41.256Z",
			"width": "240px",
			"z-index": "3"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"breakpoint": "lg",
			"menuPosition": "full"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"lg-justify-content": "center",
			"padding": "0px 16px 0px 16px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"lg-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"margin": "0px auto 0px auto",
			"lg-flex-direction": "column",
			"lg-margin": "0px auto 30px auto"
		}
	},
	"quarklycommunityKitMenu": {
		"kind": "QuarklycommunityKitMenu",
		"props": {
			"filterPages": "/cookies,/5m,/sikeres-munkahelyi-kommunikacio,/6mx-profilozas,/befolyasolas-es-profilozas-alapkurzus,/szemelyisegteszt,/terms,/privacy",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"lg-flex-direction": "column"
		}
	},
	"quarklycommunityKitMenuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration-line": "initial",
			"opacity": "0.5",
			"font": "600 16px Lexend, sans-serif",
			"color": "--dark"
		}
	},
	"quarklycommunityKitMenuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"color": "--brandSecondary",
			"opacity": "1",
			"children": ""
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"md-color": "--dark",
			"md-font": "16px/24px sans-serif",
			"letter-spacing": "0.5px",
			"md-opacity": ".5",
			"font": "--menuItems",
			"text-decoration-line": "initial",
			"color": "--dark",
			"opacity": ".5",
			"md-active-opacity": "1",
			"transition": "opacity .15s ease 0s",
			"md-transition": "opacity .15s ease 0s",
			"hover-opacity": "1",
			"text-transform": "initial",
			"display": "block",
			"flex": "0 0 auto",
			"margin": "0px 0px 0px 6px",
			"href": "https://shop.sziklaybence.com/collections/all",
			"target": "",
			"children": "Webshop"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--brandLight",
			"padding": "12px 24px 12px 24px",
			"letter-spacing": "0.5px",
			"transition": "transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s",
			"href": "https://book.sziklaybence.com/",
			"background": "--color-brandCta",
			"font": "--lead",
			"hover-transform": "translateY(-4px)",
			"children": "Feliratkozás",
			"display": "none"
		}
	}
};

const GlobalHeaderNavBar = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			md-margin="0px 16px 0px 16px"
			display="flex"
			grid-template-columns="repeat(3, 1fr)"
			md-grid-template-columns="repeat(2, 1fr)"
			margin="0px 32px 0px 32px"
			width="100%"
			flex-direction="row"
			justify-content="space-between"
			align-items="center"
		/>
		<Link {...override("link")}>
			<Image {...override("image")} />
		</Link>
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Box {...override("box")}>
				<Box {...override("box1")}>
					<QuarklycommunityKitMenu {...override("quarklycommunityKitMenu")}>
						<Override {...override("quarklycommunityKitMenuOverride")} />
						<Override {...override("quarklycommunityKitMenuOverride1")} />
					</QuarklycommunityKitMenu>
					<Link {...override("link1")} />
				</Box>
				<Link {...override("link2")} />
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		{children}
	</Section>;
};

Object.assign(GlobalHeaderNavBar, { ...Section,
	defaultProps,
	overrides
});
export default GlobalHeaderNavBar;